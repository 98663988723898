import React from 'react'
import './modals.scss'

const getPrice = (values) => {
  return (
    values.hotelcost +
    values.hotelcost_alv10 +
    values.hotelcost_alv14 +
    values.hotelcost_alv24 +
    values.travelcost +
    values.travelcost_alv10 +
    values.travelcost_alv14 +
    values.travelcost_alv24 +
    values.othercost +
    values.othercost_alv10 +
    values.othercost_alv14 +
    values.othercost_alv24
  )
}

const Receipts = (props) => {
  const { values, receipts, user, upload, disabled, setFieldValue, onReceiptDelete, translate } = props
  const price = getPrice(values)
  const disableDelete = price > 0 && receipts.length <= 1

  const receiptObjects = (receipts || []).map((receipt, key) => (
    <tr key={key}>
      <td>{receipt.date}</td>
      <td>
        <a href={`/api/billableitem/receipt/${receipt.id}?authtoken=${user.authtoken}`}>{receipt.displayname}</a>
      </td>
      <td>
        <button
          disabled={disabled || disableDelete}
          style={{ maxWidth: '100px', height: '30px' }}
          type="button"
          className="pull-right btn btn-warning"
          onClick={() => onReceiptDelete(receipt, setFieldValue)}
        >
          {translate('misc.delete')}
        </button>
      </td>
    </tr>
  ))

  if (!(receipts.length || upload.done === false)) {
    return null
  }
  return (
    <div>
      <div className="receipts-container dkblue">
        <table width="100%">
          <thead>
            <tr>
              <td>{translate('misc.date')}</td>
              <td>{translate('misc.file')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{receiptObjects}</tbody>
        </table>
      </div>
      {upload !== null && upload.done === false && (
        <div className="progress-containter">
          <div className="progress-outer">
            <div className="progress-inner" style={{ width: upload.progress + '%' }}></div>
            <div className="progress-text">
              {upload.filename} <span className="progress-value">{upload.progress}%</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Receipts
