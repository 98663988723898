import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import { connect } from 'react-redux'
import Receipts from './Receipts'
import 'moment/locale/fi'
import ReactTooltip from 'react-tooltip'
import { Modal, Button } from 'react-bootstrap'
import 'rc-time-picker/assets/index.css'
import 'react-day-picker/lib/style.css'
import './modals.scss'

const validateFn = (values) => {
  const errors = {}

  if (values.receipts && !values.receipts.length) {
    if (+values.hotelcost) {
      errors.hotelcost = 'validation.receiptRequired'
    }
    if (+values.hotelcost_alv10) {
      errors.hotelcost_alv10 = 'validation.receiptRequired'
    }
    if (+values.hotelcost_alv14) {
      errors.hotelcost_alv14 = 'validation.receiptRequired'
    }
    if (+values.hotelcost_alv24) {
      errors.hotelcost_alv24 = 'validation.receiptRequired'
    }

    if (+values.travelcost) {
      errors.travelcost = 'validation.receiptRequired'
    }
    if (+values.travelcost_alv10) {
      errors.travelcost_alv10 = 'validation.receiptRequired'
    }
    if (+values.travelcost_alv14) {
      errors.travelcost_alv14 = 'validation.receiptRequired'
    }
    if (+values.travelcost_alv24) {
      errors.travelcost_alv24 = 'validation.receiptRequired'
    }

    if (+values.othercost) {
      errors.othercost = 'validation.receiptRequired'
    }
    if (+values.othercost_alv10) {
      errors.othercost_alv10 = 'validation.receiptRequired'
    }
    if (+values.othercost_alv14) {
      errors.othercost_alv14 = 'validation.receiptRequired'
    }
    if (+values.othercost_alv24) {
      errors.othercost_alv24 = 'validation.receiptRequired'
    }
    if (+values.othercost_alv255) {
      errors.othercost_alv255 = 'validation.receiptRequired'
    }
  }
  /*
  numberFields.forEach(field => {
    if (values[field] !== '' && +values[field] < 0) {
      errors[field] = 'invalidNumber'
    }
  })
  */
  return errors
}

const renderInputField = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const changeValue = (event) => {
    let val = event.currentTarget.value.replace(/[^0-9.,]/g, '').replace(',', '.')
    if (!isNaN(val)) {
      setFieldValue(field.name, val.replace(',', '.'))
    }
  }
  let value = 0
  if (!isNaN(field.value)) {
    value = field.value.toString().replace('.', ',')
  }
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input {...field} {...props} type="text" className="form-control input-lg" value={value} onChange={changeValue} />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}

class ReimbursementOfExpencesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      progress: {},
    }
    this.onReceiptAdd = this.onReceiptAdd.bind(this)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.deleteReceipt = this.deleteReceipt.bind(this)
  }

  onUploadProgress = (progress) => {
    if (progress.done === true) {
      this.setFieldValue('receipts', [...this.props.billableItem.receipts, ...progress.file])
    }
    this.setState({
      ...this.state,
      progress: progress,
    })
  }

  onReceiptAdd = (event, setFieldValue) => {
    const {
      uploadReceipt,
      billableItem: { id },
    } = this.props

    this.setFieldValue = setFieldValue
    const file = event.target.files[0]
    file && uploadReceipt({ image: file, id: id }, this.onUploadProgress)
    event.target.value = null
    this.setState({
      ...this.state,
      progress: {
        progress: 0,
        done: false,
      },
    })
  }

  deleteReceipt = (receipt, setFieldValue) => {
    const { deleteReceipt, billableItem } = this.props
    deleteReceipt(receipt, billableItem.id)
    const receipts = this.props.billableItem.receipts.filter((id) => id === receipt.id)
    setFieldValue('receipts', receipts)
  }

  render() {
    const { handleSubmit, disabled, translate, upload, billableItem, show, onHide, onRequestHide, initialValues, user } = this.props
    const receipts = billableItem.receipts

    if (!show) return null

    const onCancel = () => {
      onRequestHide()
    }

    const disableField = (upload != null && upload.done === false) || disabled
    return (
      <Formik
        initialValues={initialValues}
        validate={validateFn}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          validateForm,
          /* and other goodies */
        }) => (
          <Modal className="timeModal details" dialogClassName="modal-90w" animation={false} show={show} onHide={onHide} backdrop={'static'}>
            <div className="valign-helper">
              <form className="time-form" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>{translate('invoice.row.reimbursementsOfExpences')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ReactTooltip id="global-invoice-disabled-tooltip" place="top" type="info" effect="solid">
                    {translate('tooltip.invoiceDisabled')}
                  </ReactTooltip>
                  <div className="row">
                    <div style={{ marginTop: '20px' }} className="col-xs-12">
                      <label className="dkblue">{translate('invoice.reimbModal.details')}</label>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <Field
                            disabled={disabled}
                            name="hotelcost_alv10"
                            type="text"
                            label={translate('invoice.reimbModal.hotelcost_alv10')}
                            translate={translate}
                            component={renderInputField}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <Field
                            disabled={disabled}
                            name="travelcost_alv10"
                            type="text"
                            label={translate('invoice.reimbModal.travelcost_alv10')}
                            translate={translate}
                            component={renderInputField}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <Field
                            disabled={disabled}
                            name="othercost_alv255"
                            type="text"
                            label={translate('invoice.reimbModal.othercost_alv24')}
                            translate={translate}
                            component={renderInputField}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-8" style={{ marginTop: '20px' }}>
                      <Receipts
                        values={values}
                        disabled={disableField}
                        setFieldValue={setFieldValue}
                        onReceiptDelete={this.deleteReceipt}
                        receipts={receipts}
                        upload={this.state.progress}
                        user={user}
                        translate={translate}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4" style={{ marginTop: '20px' }}>
                      <div disabled={disableField} className="btn btn-success white div-button pull-right">
                        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                          <div className="glyphicon icon-plus"></div>
                          {translate('invoice.reimbModal.addReceipt')}
                          <input
                            onChange={(receipt) => this.onReceiptAdd(receipt, setFieldValue)}
                            type="file"
                            disabled={disableField}
                            className="file-input"
                            style={{
                              position: 'absolute',
                              top: '0px',
                              left: '0px',
                              width: '100%',
                              height: '100%',
                              opacity: 0,
                              cursor: disableField ? 'not-allowed' : 'pointer',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row">
                    <div className="col-xs-6">
                      <Button variant="cancel" onClick={onCancel}>
                        {translate('misc.cancel')}
                      </Button>
                    </div>
                    <div className="col-xs-6">
                      <Button variant="success" disabled={disabled} type="submit">
                        {translate('misc.confirm')}
                      </Button>
                    </div>
                  </div>
                </Modal.Footer>
              </form>
            </div>
          </Modal>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    uploadReceipt: (data, progressCallback) => dispatch({ type: 'CREATE_RECEIPT', data, progressCallback }),
    deleteReceipt: (data, billableItemId) => dispatch({ type: 'DELETE_RECEIPT', data, billableItemId }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementOfExpencesForm)
