import { percent } from '../../Utils/percent'
import { path } from 'ramda'
import { ts } from '../../Utils/currency'
import React from 'react'

export const getWorkerSummary = (calculated, bill, user, translate) => {
  const salary = calculated.salary || {}
  const accident = `${percent(path(['accidentInsurance'], salary))}%`
  const sotu = `${percent(path(['sotu'], salary))}%`
  const service = `${percent(path(['personSettings', 'osk'], user))}%`
  let hasYel = false
  if (salary?.yel > 0) {
    hasYel = true
  }
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {salary.hasAlv === true && (
          <tr className="dkblue">
            <td>{translate('invoice.allInTotal')}</td>
            <td className="dkblue">
              <span className="sum pull-right">{ts(salary.amount || 0)} €</span>
            </td>
          </tr>
        )}
        <tr className="dkblue">
          <td>{translate('invoice.allInTotalWihtoutAlv')}</td>
          <td className="dkblue">
            <span className="sum pull-right">{ts(salary.totalBillingWithoutAlv || 0)} €</span>
          </td>
        </tr>
        {salary.contracting > 0 && (
          <tr className="dkblue">
            <td>{translate('invoice.contracting')}</td>
            <td className="dkblue">
              <span className="sum pull-right">{ts(salary.contracting || 0)} €</span>
            </td>
          </tr>
        )}
        <tr className="dkblue">
          <td>{translate('invoice.taxableamount')}</td>
          <td className="dkblue">
            <span className="sum pull-right">{ts(salary.bruttoSalary || 0)} €</span>
          </td>
        </tr>
        <tr className="dkblue">
          <td>{translate('invoice.holidaybonus')}</td>
          <td className="dkblue">
            <span className="sum pull-right">{ts(salary.holidaybonusamount || 0)} €</span>
          </td>
        </tr>
        {salary.total_reimbursements > 0 && (
          <tr className="dkblue">
            <td>{translate('invoice.reimbursements')}</td>
            <td className="dkblue">
              <span className="sum pull-right">{ts(salary.total_reimbursements || 0)} €</span>
            </td>
          </tr>
        )}
        {salary.billableAmountToBePaid > 0 && (
          <tr className="dkblue">
            <td>{translate('invoice.taxfree_reimbursements')}</td>
            <td className="dkblue">
              <span className="sum pull-right">{ts(salary.billableAmountToBePaid || 0)} €</span>
            </td>
          </tr>
        )}
        <tr className="dkblue">
          <td>{translate('invoice.emplyeeCost')}</td>
          <td>
            <span className="sum pull-right">{ts(salary.employeeCost || 0)} €</span>
          </td>
        </tr>
        <tr style={{ height: '50px' }}>
          <td colSpan="2"></td>
        </tr>
        {calculated.reductions < 0 && (
          <tr className="dkblue">
            <td>{translate('invoice.reductions')}</td>
            <td>
              <span className="sum pull-right">{ts(calculated.reductions || 0)} €</span>
            </td>
          </tr>
        )}

        <tr className="dkblue">
          <td>{translate('invoice.taxamount')}</td>
          <td>
            <span className="sum pull-right">{ts(salary.taxamount || 0)} €</span>
          </td>
        </tr>
        <tr className="dkblue">
          <td>{translate('invoice.unemploymentAmount')}</td>
          <td>
            <span className="sum pull-right">{ts(salary.unemploymentAmount || 0)} €</span>
          </td>
        </tr>
        <tr className="dkblue">
          <td>{translate('invoice.tyEL')}</td>
          <td>
            <span className="sum pull-right">{ts(salary.tyel || 0)} €</span>
          </td>
        </tr>
        {salary.distraint > 0 && (
          <tr className="dkblue">
            <td>{translate('invoice.distraint')}</td>
            <td>
              <span className="sum pull-right">{ts(salary.distraint || 0)} €</span>
            </td>
          </tr>
        )}
        {bill.advancePayments > 0 && (
          <tr className="dkblue">
            <td>{translate('invoice.advancePayments')}</td>
            <td>
              <span className="sum pull-right">{ts(bill.advancePayments || 0)} €</span>
            </td>
          </tr>
        )}

        <tr className="blue">
          <td data-tip data-for="global-salary">
            {translate('invoice.salary')}
          </td>
          <td>
            <span className="sum pull-right">{ts(salary.salary || 0)} €</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
